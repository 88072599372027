import cx from 'classnames';
import type { LinkProps as RouterLinkProps } from 'react-router';
import { Link as RouterLink } from 'react-router';

import type { ButtonColor, ButtonVariant as ButtonComponentVariant } from './Button';
import { getButtonClassNames } from './Button';
import { Icon, type IconProps } from './Icon';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

export type Variant = ButtonVariant;
export type LinkButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type LinkShape = 'none' | 'square' | 'circle';

export interface LinkProps extends RouterLinkProps {
  variant?: Variant;
  size?: LinkButtonSize;
  shape?: LinkShape;
  color?: ButtonColor;
  icon?: IconProps['name'];
  iconClassName?: string;
}

export function Link({
  to,
  variant = 'primary',
  size = 'md',
  shape = 'none',
  color = 'default',
  icon,
  className,
  children,
  iconClassName,
  ...rest
}: LinkProps) {
  const buttonVariant = buttonVariantByLinkVariant[variant];

  return (
    <RouterLink
      to={to}
      className={cx(
        className,
        classesByVariant[variant],
        buttonVariant ? getButtonClassNames({ variant: buttonVariant, size, shape, color }) : [],
      )}
      {...rest}
    >
      {icon ? <Icon name={icon} className={cx(buttonVariant ? '' : 'mr-2', iconClassName)} /> : null}
      {children}
    </RouterLink>
  );
}

const classesByVariant: Record<Variant, string | string[]> = {
  primary: '',
  secondary: '',
  tertiary: 'hocus:underline',
};

const buttonVariantByLinkVariant: Partial<Record<Variant, ButtonComponentVariant>> = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
};
